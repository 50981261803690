import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import ShowroomSection from "../components/showroom-section";
import ServiceHero from "../components/service-hero";
import ImageSide from "../components/image-side";
import OpeningBanner from "../components/opening-banner";
import Reviews from "../components/reviews";
import { checkPropertiesForNull } from "../utils/objectHelper";
import { SafeHtmlParser } from "../components/safe-ntml-parser";

const AboutUsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "About-1" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }

      bgImg: wpMediaItem(title: { eq: "about us-green" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }

      img1: wpMediaItem(title: { eq: "About-2" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: FULL_WIDTH
              placeholder: BLURRED
            )
          }
        }
      }
      img2: wpMediaItem(title: { eq: "work-5" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: FULL_WIDTH
              placeholder: BLURRED
            )
          }
        }
      }

      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "about-us" }) {
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        aboutUsPageFieldGroups {
          aboutBannerSection {
            heading
            description
            cta1 {
              title
              target
              url
            }
            backgroundImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          aboutTextSection {
            heading
            description
          }
          aboutTwoColumnSection1 {
            heading
            description
            cta {
              title
              target
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
            backgroundImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          aboutTwoColumnSection2 {
            heading
            description
            cta {
              target
              title
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          aboutReviewsSection {
            heading
          }
          aboutOpeningHoursSection {
            heading
          }
          aboutCtaSection1 {
            heading
            description
            cta {
              title
              target
              url
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, aboutUsPageFieldGroups },
  } = data;

  const {
    aboutBannerSection,
    aboutTextSection,
    aboutTwoColumnSection1,
    aboutTwoColumnSection2,
    aboutReviewsSection,
    aboutOpeningHoursSection,
    aboutCtaSection1,
  } = aboutUsPageFieldGroups;

  const siteUrl = data.site.siteMetadata.siteUrl;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "About Us",
        item: {
          url: `${siteUrl}/about-us`,
          id: `${siteUrl}/about-us`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>

      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/about-us`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

      <div>
        <Layout>
          {aboutBannerSection &&
            !checkPropertiesForNull(aboutBannerSection, ["heading"]) && (
              <ServiceHero
                backgroundImage={
                  aboutBannerSection.backgroundImage.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                title={aboutBannerSection?.heading}
                description={
                  <SafeHtmlParser
                    htmlContent={aboutBannerSection?.description}
                  />
                }
                ctaButton={aboutBannerSection?.cta1}
                image={aboutBannerSection?.image.node}
              />
            )}

          {aboutTextSection &&
            !checkPropertiesForNull(aboutTextSection, ["heading"]) && (
              <section className="py-5 py-lg-9">
                <Container>
                  <Row>
                    <Col lg={4}>
                      <h2 className=" fs-1 mb-4 text-primary  ">
                        <SafeHtmlParser
                          htmlContent={aboutTextSection?.heading}
                        />
                      </h2>
                    </Col>
                    <Col lg={8}>
                      <SafeHtmlParser
                        htmlContent={aboutTextSection?.description}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
          {aboutTwoColumnSection1 &&
            !checkPropertiesForNull(aboutTwoColumnSection1, ["heading"]) && (
              <section className="pb-5 pb-lg-7">
                <div className="position-relative d-none d-xl-block">
                  <GatsbyImage
                    style={{ zIndex: 2 }}
                    className="w-100 position-relative "
                    image={
                      aboutTwoColumnSection1.backgroundImage.node?.localFile
                        .childImageSharp.gatsbyImageData
                    }
                    alt={aboutTwoColumnSection1.backgroundImage.node?.altText}
                  />
                  <Container
                    style={{ zIndex: 3 }}
                    className="position-absolute pb-5 top-50 start-50 translate-middle"
                  >
                    <Row className="align-items-center pb-4 g-5">
                      <Col lg={{ span: 6 }}>
                        <GatsbyImage
                          className="w-100 "
                          image={
                            aboutTwoColumnSection1.image.node?.localFile
                              .childImageSharp.gatsbyImageData
                          }
                          alt={aboutTwoColumnSection1.image.node?.altText}
                        />
                      </Col>
                      <Col className="text-white" lg={6}>
                        <h2 className="mb-4">
                          {aboutTwoColumnSection1.heading}
                        </h2>
                        <SafeHtmlParser
                          htmlContent={aboutTwoColumnSection1?.description}
                        />
                        {aboutTwoColumnSection1.cta &&
                          aboutTwoColumnSection1.cta?.url && (
                            <Button
                              className="mt-4 w-100 w-md-auto"
                              as={Link}
                              to={aboutTwoColumnSection1.cta?.url}
                              variant="secondary"
                              target={
                                aboutTwoColumnSection1.cta?.target ?? "_blank"
                              }
                            >
                              {aboutTwoColumnSection1.cta?.title}
                            </Button>
                          )}
                      </Col>
                    </Row>
                  </Container>
                </div>
                <BgImage
                  className=" w-100 py-5 d-xl-none pb-lg-8 "
                  image={getImage(
                    aboutTwoColumnSection1.backgroundImage.node?.localFile
                      .childImageSharp.gatsbyImageData
                  )}
                >
                  <Container>
                    <Row className="align-items-center pb-4 g-5">
                      <Col lg={{ span: 6 }}>
                        <GatsbyImage
                          className="w-100 "
                          image={
                            aboutTwoColumnSection1.image.node?.localFile
                              .childImageSharp.gatsbyImageData
                          }
                          alt={aboutTwoColumnSection1.image.node?.altText}
                        />
                      </Col>
                      <Col className="text-white" lg={6}>
                        <h2 className="mb-4">
                          {aboutTwoColumnSection1.heading}
                        </h2>
                        <SafeHtmlParser
                          htmlContent={aboutTwoColumnSection1?.description}
                        />
                        {aboutTwoColumnSection1.cta &&
                          aboutTwoColumnSection1.cta?.url && (
                            <div className="text-md-center text-lg-start">
                              <Button
                                className="mt-4 w-100 w-md-auto"
                                as={Link}
                                to={aboutTwoColumnSection1.cta?.url}
                                variant="secondary"
                                target={
                                  aboutTwoColumnSection1.cta?.target ?? "_blank"
                                }
                              >
                                {aboutTwoColumnSection1.cta?.title}
                              </Button>
                            </div>
                          )}
                      </Col>
                    </Row>
                  </Container>
                </BgImage>
              </section>
            )}
          {aboutTwoColumnSection2 &&
            !checkPropertiesForNull(aboutTwoColumnSection2, ["heading"]) && (
              <ImageSide
                order="last"
                mobileImageHeight="20rem"
                svg={
                  <StaticImage
                    className="position-absolute d-none d-lg-block"
                    style={{ zIndex: 1, top: "-4rem", right: "-4rem" }}
                    quality="100"
                    src="../images/single-leaf.svg"
                    placeholder="blurred"
                    width={130}
                    formats={["auto", "webp"]}
                  />
                }
                svgPos="1rem"
                title={aboutTwoColumnSection2?.heading}
                text={
                  <span>
                    <SafeHtmlParser
                      htmlContent={aboutTwoColumnSection2?.description}
                    />
                    {aboutTwoColumnSection2.cta &&
                      aboutTwoColumnSection2.cta?.url && (
                        <Button
                          className="mt-4 w-100 w-md-auto"
                          href={aboutTwoColumnSection2.cta?.url}
                          variant="primary"
                          target={
                            aboutTwoColumnSection2.cta?.target ?? "_blank"
                          }
                        >
                          {aboutTwoColumnSection2.cta?.title}
                        </Button>
                      )}
                  </span>
                }
                imageHeight="25rem"
                image={data.img1.localFile.childImageSharp.gatsbyImageData}
                imageAlt={data.img1.altText}
              />
            )}

          <Reviews topPadding="pt-7" title={aboutReviewsSection?.heading} />

          <OpeningBanner heading={aboutOpeningHoursSection?.heading} />

          <ShowroomSection
            title={aboutCtaSection1?.heading}
            description={
              <SafeHtmlParser htmlContent={aboutCtaSection1?.description} />
            }
            ctaButton={aboutCtaSection1?.cta}
          />
        </Layout>
      </div>
    </>
  );
};

export default AboutUsPage;
